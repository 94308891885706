<div class="content-block">
    <h1 class="content-heading">our blog posts</h1>
    <div class="divider-medium"></div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="paginator">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="getPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <ul class="blog-post-list w-list-unstyled">
        <li class="blog-post-item"
            *ngFor="let blog of data | paginate: { id: 'server', itemsPerPage: 10, currentPage: p, totalItems: total }">
            <div class="blog-post-info">
                <h4 class="blog-title">{{blog.title}}</h4>
                <div class="blog-date">Posted: {{blog.dateCreated | date: 'short'}}</div>
            </div>
            <a (click)="open(blog.blogId)" class="button w-button">
                READ BLOG POST
            </a>
        </li>
    </ul>
</div>