import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasePageComponent } from 'src/app/core/base-page.component';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { BlogService } from '../../blog.service';

@Component({
  selector: 'app-blogs-generic',
  templateUrl: './blogs-generic.component.html',
  styleUrls: ['./blogs-generic.component.sass']
})
export class BlogsGenericComponent extends BasePageComponent implements OnInit {
  data = [];
  p: number = 1;
  total: number;
  loading: boolean;
  constructor(private blogService: BlogService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.loading = true;
    this.blogService.getBlogs(page).pipe(takeUntil(this.componentDestroyed$)).subscribe(response => {
      this.total = response.totalCount;
      this.p = page;
      this.loading = false;
      this.data = response.blogs.map(o => mapToBlogGeneric(o));
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public open(blogId: string) {
    this.router.navigate([`/blogs/${blogId}`]);
  }
}

export const mapToBlogGeneric = (blog) => {
  return {
    ...blog,
    author: blog.author ? `${blog.author.email ? blog.author.email : ''}` : 'Anonymous'
  }
}